import yup from '@/yup.locale';

export enum ReviewType {
  BAD,
  GOOD,
}

export const createUserSchema = yup.object().shape({
  name: yup.string().required().max(255),
});

export const createRoomSchema = yup.object().shape({
  title: yup.string().required().max(255),
  password: yup.string().optional().max(255),
});

export const createQuestionSchema = yup.object().shape({
  question: yup.string().required().max(2000),
  answer: yup.string().required().max(2000),
  answerForGpt: yup.string().optional().max(4000),
  title: yup.string().required().max(255),
  hint: yup.string().optional().max(2000),
  copyright: yup.string().optional().max(255),
  copyrightUrl: yup.string().optional().max(255),
  difficulty: yup.number().optional().min(1).max(5).default(3),
});

export const getQuestionsSchema = yup.object().shape({
  limit: yup.number().optional().max(50).default(20),
  offset: yup.number().optional().default(0),
  isIncludePlayed: yup.boolean().optional().default(false),
});

export const getRoomQuestionsSchema = yup.object().shape({
  limit: yup.number().optional().max(50).default(20),
  offset: yup.number().optional().default(0),
  roomId: yup.string().required(),
  isIncludePlayed: yup.boolean().optional().default(false),
});

export const joinRoomSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
  password: yup.string().optional().max(255),
  userId: yup.string().required().max(21),
});

export const finishQuestionSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
});

export const askQuestionSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
  message: yup.string().required().max(2000),
});

export const answerQuestionSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
  message: yup
    .string()
    .required()
    .max(2000)
    .test(
      'no-correct-answer',
      '正解というテキストを含むことはできません',
      (value) => {
        return !/正解/.test(value);
      },
    ),
});

export const pickQuestionSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
});

export const pickGyakunatorSchema = yup.object().shape({
  question: yup.string().optional().max(2000),
  answer: yup.string().required().max(255),
  roomId: yup.string().required().max(21),
});

export const reviewQuestionSchema = yup.object().shape({
  reviewType: yup.mixed().oneOf(Object.values(ReviewType)).required(),
});

export const updateUserProfileSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format')
    .max(255, 'Email must be less than 255 characters'),
  password: yup.string().nullable().max(255),
});

export const updateUserEmailSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format')
    .max(255, 'Email must be less than 255 characters'),
});

export const updateUserNameSchema = yup.object().shape({
  name: yup.string().required().max(255),
});
